import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router/index'
import store from './store'
import './plugins/vue-auth'
import axios from 'axios'
import './plugins/vue-auth'
import 'axios-progress-bar/dist/nprogress.css'
import {loadProgressBar} from 'axios-progress-bar'
import {Model} from 'vue-api-query'
import Datatable from 'vue2-datatable-component'
import VueToast from 'vue-toast-notification';
Vue.use(Datatable)
Vue.component('Datatable', Datatable)

Vue.use(VueToast, { position: 'top' })

try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {
    console.log(e);
}

import _ from 'lodash';

Object.defineProperty(Vue.prototype, '$_', {value: _});

Vue.config.productionTip = false
window.Vue = require('vue').default;

loadProgressBar()

Vue.component('index', require('./views/Index.vue').default);

Model.$http = axios

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
