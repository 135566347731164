export default [
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            roles: 'admin',
            title: 'Admin panel'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            roles: 'admin',
            title: 'Users'
        }
    },
    {
        path: '/admin/edit/:id',
        name: 'admin.user.edit',
        component: () => import('../views/admin/users/Edit.vue'),
        meta: {
            roles: 'admin',
            title: 'Edit user'
        }
    },
    {
        path: '/admin/tasks',
        name: 'admin.tasks',
        component: () => import('../views/admin/tasks/Index.vue'),
        meta: {
            roles: 'admin',
            title: 'Tasks'
        }
    },
    {
        path: '/admin/task/:id',
        name: 'admin.task',
        component: () => import('../views/admin/tasks/Edit.vue'),
        meta: {
            roles: 'admin',
            title: 'Edit task'
        }
    }
];
