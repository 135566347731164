<template>
    <div class="container">
        <router-view/>
    </div>
</template>

<script>

export default {
    name: "App",
    computed: {
        layout() {
            return `${this.$route.meta.layout || "empty"}-layout`;
        }
    },
    components: {},
    data: () => ({
        locale: 'en'
    }),
    methods: {
        init() {
        }
    },
    mounted() {
        this.init()
    }
};
</script>

<style lang="scss">
@import "../scss/app.scss";

.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 500px;
    margin: 50px auto 0;
}

</style>
