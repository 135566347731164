export default [
    {
        path: "/",
        component: () => import("../views/intro/index.vue"),
        meta: {
            title: 'ToDo'
        }
    },
    {
        path: "/login",
        component: () => import("../views/Login.vue"),
        meta: {
            title: 'Login'
        }
    },
    {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("../views/SignUp.vue"),
        meta: {
            title: 'Register'
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("../views/ForgotPassword.vue"),
        meta: {
            auth: false,
            title: 'Forgot password'
        }
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("../views/ResetPassword.vue"),
        meta: {
            auth: false,
            title: 'Reset password'
        }
    },
    {
        path: "/403",
        name: "403",
        component: () => import("../views/static/403.vue"),
        meta: {
            title: '403'
        }
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("../views/static/PrivacyPolicy.vue"),
        meta: {
            title: 'Privacy policy'
        }
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../views/home/index.vue"),
        meta: {
            auth: true,
            title: 'Home'
        }
    },
    {
        path: "/statistic",
        name: "statistic",
        component: () => import("../views/statistic/index.vue"),
        meta: {
            auth: true,
            title: 'Statistic'
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/profile/index.vue"),
        meta: {
            auth: true,
            title: 'Profile'
        }
    }
];
