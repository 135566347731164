<template>
    <div>
        <router-link to="/admin" class="admin-link" v-if="user_role === 'Admin'">Admin panel</router-link>
    </div>
</template>

<script>

export default {
    name: "Index",
    components: {},
    data: () => ({
        user_role: false
    }),
    methods: {
        async init() {
            this.$auth.user()
            if (this.$auth.user().roles[0]) {
                this.user_role = this.$auth.user().roles[0]
                if (this.user_role === 'Admin') {
                    console.log('Роль - Admin');
                } else if (this.user_role === 'Editor') {
                    console.log('Роль - Editor');
                } else {
                    console.log('Роль - Guest');
                }
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss">
.admin-link {
    color: #902e2e;
    font-size: 14px;
    padding: 10px;
    background: transparent;
    border: none;
    outline: none;
    display: block;
    margin: 10px auto;

    &:hover {
        color: #ff0000;
        cursor: pointer;
    }
}
</style>
